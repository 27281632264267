import {Injectable} from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpParams
} from '@angular/common/http';

import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {NzMessageService, NzNotificationService} from 'ng-zorro-antd';
import {isDefined} from '@angular/compiler/src/util';

@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {

  constructor(private messageService: NzMessageService, private notification: NzNotificationService,
              private router: Router, private activatedRoute: ActivatedRoute) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const credentialsReq = req.clone({ withCredentials: true, url: `/venus${req.url}` });
    // const credentialsReq = req.clone();

    let cleanedParams = new HttpParams();
    // request 需要替换成自己的
    req.params.keys().forEach(x => {
      if (isDefined(req.params.get(x))) {
        cleanedParams = cleanedParams.append(x, req.params.get(x));
      }
    });
    const credentialsReq = req.clone({headers: req.headers, params: cleanedParams});

    return next.handle(credentialsReq).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            // 判断响应体内容中的登录状态
            if (event.body.code === '0000') {
              return;
            } else if (event.body.code === '9000') {
              // 操作失败
              this.messageService.warning(event.body.remark, {
                nzDuration: 5000
              });
              throw new Error(event.body.remark);
            } else if (event.body.code === '9001') {
              this.notification.create(
                'error',
                '开小差了',
                '系统开小差了,请重新尝试或联系系统管理员[' + event.url + '].',
                {
                  nzDuration: 10000,
                  nzAnimate: true,
                }
              );
              throw new Error(event.body.remark);
            } else if (event.body.code === '9002') {
              //登录超时
              this.notification.create(
                'warning',
                '登录超时',
                '当前登录用户已离线,请重新登录后进行操作.',
              );
              this.router.navigate(['/login']);
              throw new Error('登录超时');
            }
          }
        },
        error => {
          console.log('error in calling API : ', error);
          this.notification.create(
            'error',
            error.status + ' ' + error.statusText,
            error.message,
            {
              nzDuration: 6000,
              nzAnimate: true,
              nzStyle: {
                wordWrap: 'break-word'
              }
            }
          );
        }
      )
    );
  }
}
