import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalVariable } from '../common/Config';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient) {
  }

  login(params: any) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-type", "application/x-www-form-urlencoded");
    return this.http.post(GlobalVariable.base_path + "/login", params, { headers: headers }).toPromise();
  }

  sendLoginSms() {
    let headers = new HttpHeaders();
    headers = headers.append("Content-type", "application/x-www-form-urlencoded");
    return this.http.post(GlobalVariable.base_path + "/send/login/sms", {}, { headers: headers }).toPromise();
  }

  validateLoginSms(params: any) {
    let headers = new HttpHeaders();
    headers = headers.append("Content-type", "application/x-www-form-urlencoded");
    return this.http.post(GlobalVariable.base_path + "/validate/login/sms", params, { headers: headers }).toPromise();
  }

  logout() {
    let headers = new HttpHeaders();
    headers = headers.append("Content-type", "application/x-www-form-urlencoded");
    return this.http.post(GlobalVariable.base_path + "/logout", {}, { headers: headers }).toPromise();
  }
}
