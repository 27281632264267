import {NgModule} from '@angular/core';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IconsProviderModule} from '../icons-provider.module';
import {HttpClientModule} from '@angular/common/http';
import {NgZorroAntdModule, NZ_I18N, zh_CN} from 'ng-zorro-antd';

/** 配置 angular i18n **/
import {registerLocaleData, CommonModule} from '@angular/common';
import zh from '@angular/common/locales/zh';

registerLocaleData(zh);


import {LayoutRoutingModule} from './layout-routing.module';
import {LayoutComponent} from './layout.component';

@NgModule({
  declarations: [
    LayoutComponent
  ],
  imports: [
    CommonModule,
    IconsProviderModule,
    FormsModule,
    HttpClientModule,
    /** 导入 ng-zorro-antd 模块 **/
    NgZorroAntdModule,
    ReactiveFormsModule,

    LayoutRoutingModule,
  ],
  providers: [],
  exports: [
    LayoutComponent
  ]

})
export class LayoutModule {
}
