import {Component, OnInit, HostListener} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LayoutService} from './layout.service';
import {Title} from '@angular/platform-browser';
import {fromEvent} from 'rxjs';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less']
})
export class LayoutComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private layoutService: LayoutService,
              private titleService: Title) {
  }

  modules: any[];

  user = <any> {};

  isMenuCollapsed = false;


  ngOnInit() {
    this.moduleTree();
    this.currentUser();
    // window.addEventListener('scroll', this.scroll, true);
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     console.log(event.url);
    //     console.log(this.activatedRoute.queryParams);
    //     // if (title) {
    //     //   this.titleService.setTitle(title);
    //     // }
    //   }
    // });

    this.activatedRoute.queryParams.subscribe((res) => {
      console.log(res.title);
      this.titleService.setTitle(res.title);
    });

  }

  // scroll = (event: any): void => {
  //   // Here scroll is a variable holding the anonymous function
  //   // this allows scroll to be assigned to the event during onInit
  //   // and removed onDestroy
  //   // To see what changed:
  //   const number = event.srcElement.scrollTop;
  //   console.log(event);
  //   console.log('I am scrolling ' + number);
  // };

  moduleTree() {
    this.layoutService.moduleTree().then((data: any) => {
      this.modules = data.result;
    }).catch(error => {
    });
  }

  currentUser() {
    this.layoutService.currentUser().then((data: any) => {
      this.user = data.result;
    }).catch(error => {
    });
  }

  logout() {
    this.layoutService.logout().then((data: any) => {
      this.router.navigateByUrl('/login');
    }).catch(error => {
    });
  }

  modifyPasswordModalShow() {
    alert('modify password click');
  }
}
