export const GlobalVariable = Object.freeze({
  base_path: '/operation/api'
});

export const BenefitsGoodsType = [
  {label: '音乐', value: 'music'},
  {label: '视频', value: 'movie'},
  {label: '阅文', value: 'book'},
  {label: 'QQ业务', value: 'qqBiz'},
  {label: '出行', value: 'trip'},
  {label: '天猫权益', value: 'tmall'},
];
