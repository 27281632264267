import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {GlobalVariable} from '../common/Config';


@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  constructor(private http: HttpClient) {

  }

  moduleTree() {
    return this.http.get(GlobalVariable.base_path + '/module/tree').toPromise();
  }


  currentUser() {
    return this.http.get(GlobalVariable.base_path + '/current/user').toPromise();
  }

  logout() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-type', 'application/x-www-form-urlencoded');
    return this.http.post(GlobalVariable.base_path + '/logout', {}, {headers: headers}).toPromise();
  }

  childCodeList(parentCode: string) {
    let httpParams = new HttpParams({
      fromObject: {
        parentCode: parentCode
      }
    });
    return this.http.get(GlobalVariable.base_path + '/child/code/list', {params: httpParams}).toPromise();
  }
}
