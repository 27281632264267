import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import * as qs from 'qs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  validateForm: FormGroup;

  smsCodeHidden = true;

  smsSendButtonText = "发送验证码";

  smsSendSecondTimer: any;

  smsSendSecond = 0;

  successLoading = false;

  constructor(private fb: FormBuilder, private router: Router, private loginService: LoginService) { }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      loginAccount: [null, [Validators.required]],
      loginPassword: [null, [Validators.required]],
      smsCode: [null, null],
    });
  }

  loginBtnClick(): void {
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      if (this.smsCodeHidden) {
        this.validateLoginPwd();
      } else {
        this.validateLoginSms();
      }
    }
  }

  validateLoginPwd(): void {
    this.loginService.login(qs.stringify(this.validateForm.value, { arrayFormat: 'brackets' })).then((data: any) => {
      if (data.result == 'pwd') {
        this.successLoading = true;
        this.router.navigateByUrl("/home");
      } else if (data.result == 'sms') {
        this.smsCodeHidden = false;
        this.validateForm.get('smsCode').setValidators(Validators.required);
        this.sendLoginSms();
      }
    });
  }

  sendLoginSms(): void {
    this.loginService.sendLoginSms().then((data: any) => {
      if (data.result == 'unvalidatepwd') {
        this.smsCodeHidden = true;
        this.validateForm.get('smsCode').setValidators([]);
      }
      this.smsSendSecond = 30;
      this.smsSendSecondTimer = setInterval(() => {
        if (this.smsSendSecond <= 0) {
          clearInterval(this.smsSendSecondTimer);
          this.smsSendSecondTimer = null;
          this.smsSendButtonText = '发送验证码';
        } else {
          this.smsSendSecond = this.smsSendSecond - 1;
          this.smsSendButtonText = `${this.smsSendSecond}S`;
        }
      }, 1000)
    });
  }

  keyDown(event: any) {
    if (event.keyCode == 13) {
       this.loginBtnClick();
       return false;
    }
  }

  validateLoginSms(): void {
    this.loginService.validateLoginSms(qs.stringify({ smsCode: this.validateForm.value.smsCode }, { arrayFormat: 'brackets' })).then((data: any) => {
      this.successLoading = true;
      this.router.navigateByUrl("/home");
    });
  }
}
